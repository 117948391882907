<template>
  <div style="max-width: 1200px; margin: 0 auto">
    <div v-for="item in _storePricin" :key="item.Id" style="margin-top: 20px">
      <div
        style="width: calc(100% - 50px); margin: 0 auto; border: 1px solid #eee"
      >
        <!-- <img src="../../../assets/images/tuijianbj.jpg" style="width: 100px;position: absolute;right: 0px;"> -->
        <div
          style="
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            background-color: #434343;
            color: aliceblue;
          "
        >
          <span style="margin-left: 10px">{{ item.Name }}</span>
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            height: 75px;
            width: 100%;
            align-items: center;
          "
        >
          <div style="margin-left: 10px">
            {{ item.Amount }}  
            {{ tools.GetLanguageValue("web.时长") }}{{ item.Day }}
            {{ tools.GetLanguageValue("web.天") }}
            <span v-if="item.GiveDay > 0">
              {{ tools.GetLanguageValue("web.赠送") }}
              {{ item.GiveDay }} {{ tools.GetLanguageValue("web.天") }}
            </span>
            <!-- <span>
                    {{ tools.GetLanguageValue("web.账号数量") }}
                    {{ item.AccountMaxCount }}个
                  </span> -->
          </div>
          <div style="margin: 10px">
            <el-button @click="OpenRenewWindow(item)">{{
              tools.GetLanguageValue("web.选择")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-card
      class="box-card"
      style="
        max-width: calc(100% - 20px);
        margin: 0 auto;
        margin-top: 50px;
        border: 1px solid var(--el-menu-border-color);
      "
    >
      <template #header>
        <span> {{ tools.GetLanguageValue("web.申请记录") }}</span>
      </template>
      <el-row>
        <el-table
          border
          :data="renewRecords"
          :height="300 + 'px'"
          style="width: calc(100% - 1px)"
        >
          <el-table-column type="index" width="50"> </el-table-column>

          <el-table-column
            :label="tools.GetLanguageValue('web.套餐名称')"
            width="280"
            prop="ProjectName"
          />
          <el-table-column
            :label="tools.GetLanguageValue('web.编号')"
            width="180"
            prop="Number"
          />
          <el-table-column
            :label="tools.GetLanguageValue('web.支付方式')"
            width="180"
            prop="PayType"
          />

          <el-table-column
            :label="tools.GetLanguageValue('web.天')"
            width="130"
            prop="Count"
          >
            <template #default="scope"> +{{ scope.row.Count }} </template>
          </el-table-column>
          <el-table-column
            :label="tools.GetLanguageValue('web.创建时间')"
            width="180"
            prop="CreateTimeFormat"
          />

          <el-table-column
            :label="tools.GetLanguageValue('web.操作')"
            fixed="right"
            width="200"
          >
            <template #default="scope">
              <el-button
                v-if="!scope.row.IsDispose"
                size="small"
                @click="OpenApplyInfoWindow(scope.row)"
                type="primary"
              >
                {{ tools.GetLanguageValue("web.详细") }}
              </el-button>
              <!-- <el-button
                v-if="!scope.row.IsDispose"
                size="small"
                @click="DeleteRenew(scope.row)"
              >
                {{ tools.GetLanguageValue("web.撤销") }}
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>

    <div index="renew">
      <!-- 申请信息 -->
      <el-dialog
        v-model="_renewWindow"
        :title="tools.GetLanguageValue('web.申请信息') + '-' + storeInfo.Name"
        width="800px"
      >
        <div style="font-size: 13pt; text-align: center; margin-top: 30px">
          {{ tools.GetLanguageValue("web.您选择的是") }}
          <span style="color: red; font-size: 18pt"> {{ RenewOpt.Name }}</span
          >{{ tools.GetLanguageValue("web.套餐") }}
          {{ tools.GetLanguageValue("web.时长是") }}
          <span style="color: red; font-size: 18pt"> {{ RenewOpt.Day }}</span
          >{{ tools.GetLanguageValue("web.天") }} ,
          {{ tools.GetLanguageValue("web.赠送天数是") }}{{ RenewOpt.GiveDay }},
          {{ tools.GetLanguageValue("web.价格是") }}
          <span style="font-size: 15pt; color: red">
            {{ RenewOpt.Amount }}
          </span>
        </div>
        <br />
        <br />
        <br />

        <el-divider content-position="left">{{
          tools.GetLanguageValue("web.支付信息")
        }}</el-divider>

        <div style="text-align: center">
          <br />
          <br />
          <br />
          <el-radio-group v-model="PayType">
            <el-radio
              value="TRANSFER"
              size="large"
              :label="tools.GetLanguageValue('web.转账')"
              border
            >
              TRANSFER
            </el-radio>
            <el-radio
              value="wechat"
              size="large"
              :label="tools.GetLanguageValue('web.微信')"
              border
            >
              {{ tools.GetLanguageValue("web.微信") }}</el-radio
            >
            <el-radio value="BIZUM" size="large" label="BIZUM" border>
              BIZUM</el-radio
            >
          </el-radio-group>
        </div>
        <!-- <el-divider content-position="left">{{
          tools.GetLanguageValue("web.备注")
        }}</el-divider>
        <WangEditor :height="200" ref="editorModel"> </WangEditor> -->
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div style="width: 100%; display: flex; justify-content: space-between">
          <el-button
            @click="_renewWindow = false"
            style="width: 120px; height: 40px"
          >
            <el-icon style="font-size: 16pt">
              <back />
            </el-icon>

            {{ tools.GetLanguageValue("web.后退") }}
          </el-button>

          <el-button
            type="primary"
            @click="CreateRenew(RenewOpt)"
            style="width: 120px; height: 40px"
          >
            {{ tools.GetLanguageValue("web.确定") }}
          </el-button>
        </div>

        <!-- 加载所有的申请记录，并且只能有一个未支付的信息 -->

        <!-- <el-upload
          class="avatar-uploader"
          :action="UploadPictureImageUrl"
          :show-file-list="false"
          :on-success="UploadSuccess"
          :before-upload="UploadSuccess"
          :headers="headersImage"
        >
          <img
            v-if="RenewOpt.PayImgFormat"
            :src="RenewOpt.PayImgFormat"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <el-form-item
          :label="tools.GetLanguageValue('web.支付说明')"
          v-if="false"
        >
          <el-input v-model="RenewOpt.Remark" type="textarea" />
        </el-form-item>
        <el-divider content-position="left">{{
          tools.GetLanguageValue("web.支付方式")
        }}</el-divider>
        <el-radio-group v-model="PayType">
          <el-radio value="transfer" size="large" :label='tools.GetLanguageValue("web.转账")' border>
            {{ tools.GetLanguageValue("web.转账") }}
          </el-radio>
          <el-radio value="wechat" size="large" :label='tools.GetLanguageValue("web.微信")' border>
            {{ tools.GetLanguageValue("web.微信") }}</el-radio
          >
          <el-radio value="alipay" size="large" :label='tools.GetLanguageValue("web.支付宝")' border>
            {{ tools.GetLanguageValue("web.支付宝") }}</el-radio
          >
        </el-radio-group>
        <el-divider content-position="left">{{
          tools.GetLanguageValue("web.支付说明")
        }}</el-divider>
        <WangEditor :height="200" ref="editorModel"> </WangEditor>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="_renewWindow = !_renewWindow">{{
              tools.GetLanguageValue("web.关闭")
            }}</el-button>
            <el-button @click="Renew(RenewOpt)" type="primary">{{
              tools.GetLanguageValue("web.保存")
            }}</el-button>
          </span>
        </template> -->
      </el-dialog>

      <!-- 支付信息 -->
      <el-dialog
        v-model="applyInfoWindow"
        :title="tools.GetLanguageValue('web.支付信息')"
        width="800px"
        :draggable="true"
        :show-close="false"
      >
        <div style="width: 100%; text-align: center">
          <div v-if="applyInfoDetail.PayType == 'wechat'">
            <p style="font-size: 18pt">
              {{ applyInfoDetail.ProjectName }}
            </p>
            <img :src="wechat" style="width: 250px" />
          </div>

          <div v-if="applyInfoDetail.PayType == 'BIZUM'">
            <div
              style="
                margin: 0 auto;
                height: 55px;
                font-size: 19pt;
                margin: 10px;
              "
            >
              {{ tools.GetLanguageValue("web.转账账号") }}, Bizum:
            </div>
            <div
              style="
                margin: 0 auto;
                height: 55px;
                font-size: 19pt;
                margin: 10px;
              "
            >
              603 409 999
            </div>
            <img :src="wechat" style="width: 100px" />
          </div>
          <div v-if="applyInfoDetail.PayType == 'TRANSFER'">
            <div
              style="
                margin: 0 auto;
                height: 55px;
                font-size: 19pt;
                margin: 10px;
              "
            >
              {{ tools.GetLanguageValue("web.转账账号") }}, La Caixa:
            </div>
            <div
              style="
                margin: 0 auto;
                height: 55px;
                font-size: 19pt;
                margin: 10px;
              "
            >
              ES03 2100 3004 1321 0944 8366
            </div>

            <img :src="wechat" style="width: 100px" />
          </div>

          <p>
            请在转账的时候写上 "订单Id:<span
              style="color: red; font-size: 18pt"
              >{{ applyInfoDetail.Number }}</span
            >"，然后通知我们的客服
          </p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
    <script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox } from "element-plus";
import PictureCover from "../../../components/PictureCover.vue";
import WangEditor from "../../../components/WangEditor.vue";
import {
  Search,
  PieChart,
  TrendCharts,
  Plus,
  Fold,
  Expand,
  Setting,
  User,
  Back,
  Refresh,
  Cpu,
  Goods,
  View,
  UserFilled,
  Box,
  List,
  DocumentCopy,
  Comment,
  Apple,
  Help,
  Coin,
  SwitchButton,
  Ticket,
} from "@element-plus/icons-vue";
const editorModel = ref(null); // 创建一个 ref

const route = useRoute();

// 页面加载前
onBeforeMount(() => {});
// 页面加载
onMounted(() => {
  GetStorePricing();
  GetStoreInfo();
  GetRenewRecords(false);

  headersImage.value = {
    Authorization: localStorage.getItem("Authentication_Header"),
  };
  UploadPictureImageUrl.value = axios.GetUrl() + "/api/Upload/ImageFileWebp";
});

const wechat = require("../../../../public/wechat_qr.png");

const ApplyforRenewWindow = ref(false);
// 创建申请
const OpenApplyforRenewWindow = () => {
  ApplyforRenewWindow.value = true;
};

//店铺信息
const storeInfo = ref({});

// 删除申请记录
const DeleteRenew = (item) => {
  ElMessageBox.confirm(
    tools.GetLanguageValue("web.您确定要撤销申请吗"),
    tools.GetLanguageValue("web.提示"),
    {
      dangerouslyUseHTMLString: true,
    }
  )
    .then(() => {
      axios.apiMethod(
        "/stores/System/DeleteRenew",
        "get",
        { renewId: item.Id },
        (result) => {
          GetRenewRecords(false);
        }
      );
    })
    .catch(() => {});
};

const renewRecords = ref([]);

// 获取申请记录
const GetRenewRecords = (open) => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/System/GetRenewsById",
    "get",
    { storeId: route.query.storeId },
    (result) => {
      renewRecords.value = result.Data;
      if (open) {
        renewRecords.value.forEach((e) => {
          if (e.IsDispose == false) {
            OpenApplyInfoWindow(e);
            return;
          }
        });
      }
    }
  );
};

// 获取店铺信息
const GetStoreInfo = () => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/System/GetStoreInfoByStoreId",
    "get",
    { storeId: route.query.storeId },
    (result) => {
      storeInfo.value = result.Data;
    }
  );
};

const headersImage = ref({});

const UploadPictureImageUrl = ref("");

// 上传成功操作
const UploadSuccess = (response, file, fileList) => {
  if (response.Code == 200) {
    RenewOpt.value.PayImgFormat = response.Data.ImageFormet;
    RenewOpt.value.PayImg = response.Data.FileName;
  }
};

const applyInfoWindow = ref(false);
const applyInfoDetail = ref({});

///打开支付详细
const OpenApplyInfoWindow = (item) => {
  applyInfoWindow.value = true;
  applyInfoDetail.value = item;
};

const _renewWindow = ref(false);
const RenewOpt = ref({}); //当前选择
//打开续费编辑页面
const OpenRenewWindow = (item) => {
  _renewWindow.value = true;
  RenewOpt.value = item;
};

const PayType = ref("wechat");
// 创建续费申请
const CreateRenew = (item) => {
  // var content = editorModel.value.editor.txt.html();

  var prams = {
    ProjectId: item.Id,
    PayImg: item.PayImg,
    Remark: "",
    PayType: PayType.value,
    StoreId: route.query.storeId,
  };

  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/System/CreateRenewByStoreId",
    "post",
    prams,
    (result) => {
      if (result.Data.IsSuccess) {
        GetRenewRecords(true);
        _renewWindow.value = false;
        ApplyforRenewWindow.value = false;
        return;
      } else {
        ElMessageBox({
          title: tools.GetLanguageValue("web.提示"),
          message: tools.GetLanguageValue("web.有申请未完成"),
          type: "error",
        });
      }
    }
  );
};

const _storePricin = ref([]);

// 获取可用续费列表
const GetStorePricing = () => {
  // 改变之后调用更新方法
  axios.apiMethod("/stores/System/GetStorePricing", "post", {}, (result) => {
    _storePricin.value = result.Data;
  });
};
</script>
    <style>
fieldset {
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  padding: 16px;
}
fieldset legend {
  padding: 0 8px !important;
  font-size: 16px !important;
  width: auto !important;
  border: none !important;
  margin-bottom: 0 !important;
}
</style>
    
